import React, { useEffect, useState } from 'react';

import { zIndex as levels } from 'config/constants';

import Icon, { names } from 'components/core/Icon';
import Overlay from 'components/core/Overlay';

import { Close, Container } from './styles';

export default function Modal({
  blurBackground, show, children, zIndex, canClose, onCloseModal, cy,
}) {
  const [isVisible, setIsVisible] = useState(show);

  useEffect(() => setIsVisible(show), [show]);

  function handleCloseClick() {
    if (canClose) {
      setIsVisible(false);
      onCloseModal();
    }
  }

  return (
    <>
      <Overlay isVisible={isVisible} onClick={handleCloseClick} />
      <Container data-cy={cy} blurBackground={blurBackground} zIndex={zIndex} show={isVisible}>
        {
          canClose && (
            <Close onClick={handleCloseClick}>
              <Icon name={names.close} />
            </Close>
          )
        }
        {children}
      </Container>
    </>
  );
}

Modal.defaultProps = {
  blurBackground: false,
  canClose: true,
  show: false,
  zIndex: levels.lv4,
  onCloseModal: () => { /* */ },
};
